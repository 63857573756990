import React from 'react'
import { Badge } from 'theme-ui'

const style = {
  color: '#fff',
  bg: '#5277f9',
  fontFamily: 'body',
  fontSize: '16px',
  paddingX: '8px',
  borderRadius: '4px'
}

const Definition = ({ tags, ...props }) => (
  <Badge variant='accent' sx={style}>
    {props.content}
  </Badge>
)
export default Definition
