import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card } from 'theme-ui'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { isInDarkMode } from '@layout/Header/Header.ColorMode'

function AnswerBlock({ tags, ...props }) {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <div style={{ marginBottom: '2rem' }}>
      <Button color='primary' onClick={toggle} block>
        {props.title || '👀参考答案'}
      </Button>
      <Modal isOpen={modal} toggle={toggle} size='lg' centered scrollable>
        <ModalHeader
          style={{ backgroundColor: isInDarkMode() ? '#2D3748' : '#fff' }}
          toggle={toggle}
        >
          <strong>{props.title}</strong>
        </ModalHeader>
        <ModalBody
          style={{
            backgroundColor: isInDarkMode() ? '#1A202C' : '#F8F8F8',
            maxHeight: '400px'
          }}
        >
          <Card variant='paper-lg'>{props.children}</Card>
        </ModalBody>
        <ModalFooter
          style={{ backgroundColor: isInDarkMode() ? '#2D3748' : '#fff' }}
        >
          <Button color='primary' onClick={toggle} block>
            👍了解！
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AnswerBlock
