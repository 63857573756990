import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'

import SyntaxHighlighter from 'react-syntax-highlighter'
import { isInDarkMode } from '@layout/Header/Header.ColorMode'
import {
  atomOneDark,
  atomOneLight
} from 'react-syntax-highlighter/dist/esm/styles/hljs'

function CodeTabs(props) {
  const data = props.data

  const [active, setActive] = useState('1')

  const toggle = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }

  return (
    <>
      <Nav tabs>
        {data.map((code, index) => {
          let isActive = active === (index + 1).toString()
          return (
            <NavItem key={index}>
              <NavLink
                active={isActive}
                style={{
                  color: isInDarkMode()
                    ? isActive
                      ? '#2D3748'
                      : '#EDF2F7'
                    : isActive
                    ? '#0d6efd'
                    : '#2D3748'
                }}
                onClick={() => {
                  toggle((index + 1).toString())
                }}
              >
                {code.title}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={active}>
        {data.map((code, index) => {
          return (
            <TabPane tabId={(index + 1).toString()} key={index}>
              <SyntaxHighlighter
                language={props.language}
                style={isInDarkMode() ? atomOneDark : atomOneLight}
                showLineNumbers
                lineNumberStyle={{ opacity: '50%' }}
                customStyle={{
                  fontSize: `clamp(1rem, 2.5vw, 1.25rem)`,
                  borderRadius: '8px'
                }}
              >
                {code.codeString}
              </SyntaxHighlighter>
            </TabPane>
          )
        })}
      </TabContent>
    </>
  )
}

export default CodeTabs
