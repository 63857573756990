import React, { Component } from 'react'
import JXGBoard from 'jsxgraph-react-js'

let logicJS = brd => {
  brd.suspendUpdate()
  var axisx = brd.create(
    'axis',
    [
      [0, 0],
      [1, 0]
    ],
    {
      firstArrow: false,
      lastArrow: true,
      ticks: {
        drawZero: true,
        ticksDistance: 1,
        majorHeight: 8,
        tickEndings: [1, 1],
        minorTicks: 1
      }
    }
  )
  let point1 = brd.create('glider', [-1, 0, axisx], {
    name: 'a',
    strokeColor: '#e95016',
    fillColor: '#fff',
    showInfobox: false
  })
  let point2 = brd.create('glider', [1, 0, axisx], {
    name: 'b',
    strokeColor: '#e95016',
    fillColor: '#fff',
    showInfobox: false
  })
  // point events
  point1.on('up', () => {
    if (point1.X() > point2.X()) {
      const temp = point1.X()
      point1.moveTo([point2.X(), 0])
      point2.moveTo([temp, 0])
    }
  })
  point2.on('up', () => {
    if (point2.X() < point1.X()) {
      const temp = point1.X()
      point1.moveTo([point2.X(), 0])
      point2.moveTo([temp, 0])
    }
  })
  // checkboxes
  const aCheckbox = brd.create(
    'checkbox',
    [-3, 2, `<div style = "margin-left : 4px">A is Open</div>`],
    {
      fontSize: 14,
      fixed: true,
      checked: true
    }
  )
  aCheckbox.on('down', () => {
    point1.setAttribute({
      fillColor: `#${!aCheckbox.Value() ? 'fff' : 'e95016'}`
    })
  })

  const bCheckbox = brd.create(
    'checkbox',
    [-2.25, 2, `<div style = "margin-left : 4px">B is Open</div>`],
    {
      fontSize: 14,
      fixed: true,
      checked: true
    }
  )
  bCheckbox.on('down', () => {
    point2.setAttribute({
      fillColor: `#${!bCheckbox.Value() ? 'fff' : 'e95016'}`
    })
  })

  // text
  brd.create(
    'text',
    [
      0,
      -2,
      () => {
        return `${aCheckbox.Value() ? '(' : '['} ${point1
          .X()
          .toFixed(1)}, ${point2.X().toFixed(1)} ${
          bCheckbox.Value() ? ')' : ']'
        } = { x | ${point1.X().toFixed(1)} ${aCheckbox.Value() ? '<' : '≤'} x ${
          bCheckbox.Value() ? '<' : '≤'
        } ${point2.X().toFixed(1)} }`
      }
    ],
    { anchorX: 'middle', fontSize: 18, fixed: true }
  )
  // create segment connects point 1 * point 2
  brd.create('segment', [point1, point2], { strokeColor: '#5277F9' })

  brd.unsuspendUpdate()
}

class Interval extends Component {
  render() {
    return (
      <>
        <JXGBoard
          logic={logicJS}
          boardAttributes={{
            showCopyright: false,
            showNavigation: false,
            boundingbox: [-3.2, 3, 3.2, -3],
            fixed: true
          }}
          style={{
            width: '100%',
            height: '96px',
            marginBottom: '2rem',
            backgroundColor: '#ffffffe1',
            borderRadius: '8px'
          }}
        />
      </>
    )
  }
}

export default Interval
