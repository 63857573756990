import Mark from "../../../../src/components/Mark.jsx";
import CSharpHighlighter from "../../../../src/components/CSharpHighlighter.jsx";
import 'katex/dist/katex.min.css';
import Tex from '@matejmazur/react-katex';
import * as React from 'react';
export default {
  Mark,
  CSharpHighlighter,
  Tex,
  React
};