import React from 'react'

const Mark = ({ tags, ...props }) => (
  <>
    {` `}
    <mark
      style={{
        borderRadius: '0.3em',
        backgroundColor: `#EDF2F7`,
        color: `#4a5568`
      }}
    >
      {props.children}
    </mark>
    {` `}
  </>
)

export default Mark
