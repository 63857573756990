import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { isInDarkMode } from '@layout/Header/Header.ColorMode'
import {
  atomOneDark,
  atomOneLight
} from 'react-syntax-highlighter/dist/esm/styles/hljs'

const Highlighter = props => {
  return (
    <SyntaxHighlighter
      language={props.language}
      style={isInDarkMode() ? atomOneDark : atomOneLight}
      showLineNumbers
      lineNumberStyle={{ opacity: '50%' }}
      customStyle={{
        fontSize: `clamp(1rem, 2.5vw, 1.25rem)`,
        borderRadius: '8px'
      }}
    >
      {props.codeString}
    </SyntaxHighlighter>
  )
}

export default Highlighter
